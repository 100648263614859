#social

	a
		text-decoration: none

	.social-item
		flex-basis: calc(100% / 5)

		.social-item-icon
			padding: 1rem
			text-align: center

		.social-item-text
			text-align: center
			white-space: nowrap

		&:hover
			.social-item-text, .social-item-icon
				color: var(--primary-color)
